import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { connect } from 'react-redux';

/* PATH */
import {
  loginAppPath,
  setPasswordPath,
  refreshEmailPath,
  errorPath,
  trustySuccessPath,
  repairStatus,
} from 'utils/newRoutingPaths';
// import Ring from 'components/Ring';
import ErrorBoundary from '../components/Layout/ErrorBoundary';
import ProtectedRoutes from '../components/Auth/ProtectedRoutes';
import Ring from 'components/Ring';
import { isSG } from 'utils/helpers';
import RepairStatusPage from 'pages/RepairStatus';

const AuthRouter = lazy(() => import('routes/AuthRouter'));
const Login = lazy(() => import('pages/Login'));
const SetPassword = lazy(() => import('pages/SetPassword'));
const RefreshEmail = lazy(() => import('pages/RefreshEmail'));
const NotFound = lazy(() => import('pages/NotFound'));
const TrustySuccess = lazy(() => import('pages/TrustySuccess'));

function MainRouter() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Ring />}>
        <Routes>
          <Route path={`${loginAppPath}/*`} element={<Login />} />
          <Route path={`${setPasswordPath}/*`} element={<SetPassword />} />
          <Route path={`${refreshEmailPath}/*`} element={<RefreshEmail />} />
          <Route path={errorPath} element={<NotFound />} />
          <Route path={trustySuccessPath} element={<TrustySuccess />} />
          {isSG() ? (
            <Route path={`${repairStatus}/*`} element={<RepairStatusPage />} />
          ) : null}

          <Route
            path="/*"
            element={
              <ProtectedRoutes>
                <AuthRouter />
              </ProtectedRoutes>
            }
          ></Route>
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
}
// }

const mapStateToProps = ({ session, claim, auth }) => ({
  session,
  claim,
  auth,
});

export default connect(mapStateToProps)(MainRouter);
