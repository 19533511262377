import {
  ALLOWED_DOCUMENT_FILE_TYPES,
  ALLOWED_FILE_TYPES,
  ALLOWED_IMAGE_FILE_TYPES,
  ALLOWED_VIDEO_FILE_TYPES,
  ANSWER_TYPES,
  SELECTED_CLAIMS_LIMIT,
} from './constants';

export const getNumberValidationError = (minNumber, maxNumber) => {
  if (minNumber && maxNumber)
    return `This number should be between ${minNumber} and ${maxNumber}`;
  if (minNumber && !maxNumber)
    return `This number should be more than ${minNumber}`;
  if (maxNumber && !minNumber)
    return `This number should be less than ${maxNumber}`;
};

export const VALIDATION_ERROR_TEXT = {
  [ANSWER_TYPES.drivingLicence]:
    'Your driving licence should have the following format: LLLLLNNNNNNLLNLL',
  [ANSWER_TYPES.phone]:
    'It looks like this phone number is not valid. Please review and try again.',
  missedFileExtention: 'There is no file extention in the file.',
  missedFileType: 'There is no file type in the file.',
  videoFileUploading: `Only these types of file (${ALLOWED_VIDEO_FILE_TYPES.join(
    ', '
  )}) are supported for uploading.`,
  imageFileUploading: `Only these types of file (${ALLOWED_IMAGE_FILE_TYPES.join(
    ', '
  )}) are supported for uploading.`,
  documentFileUploading: `Only these types of file (${ALLOWED_DOCUMENT_FILE_TYPES.join(
    ', '
  )}) are supported for uploading.`,
  invalidAnswer:
    'Oops, it looks like one of your answers is invalid. Please update it and try again.',
  agentAndCustomerDifferentOrganisations:
    'A customer and an agent should be from the same organisation.',
  fileUploading: `Only these types of file (${ALLOWED_FILE_TYPES.join(
    ', '
  )}) are supported for uploading.`,
  [ANSWER_TYPES.email]:
    'It looks like this is not valid email. Please review and try again.',
  [ANSWER_TYPES.text]: 'Please review this answer.',
};

export const SELECTED_CLAIMS_LIMIT_EXCEED = `Please select a maximum of ${SELECTED_CLAIMS_LIMIT} claims at a time`;
