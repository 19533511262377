// APP paths
export const rootAppPath = '';
export const tasksAppPath = 'tasks';
export const claimsAppPath = 'claims';
export const claimsNewPath = `${claimsAppPath}/new`;
export const customersAppPath = 'customers';
export const peoplesAppPath = 'people';
export const organizationsAppPath = 'organisations';
export const errorPath = '/not-found';
export const loginAppPath = '/login';
export const accountAppPath = 'account';
export const ReusableComponentsPath = '/reusable-components';
export const setPasswordPath = '/set-password';
export const refreshEmailPath = '/me/confirm';
export const fingerprintsAppPath = '/fingerprints';
export const trustySuccessPath = '/trustly_account_selection_success_custom';
// S&G Repair Status Page
export const repairStatus = '/repair-status';
export const repairStatusLogin = 'repair-status/login';

// GENERAL
export const idPath = ':id';
export const newPath = 'new';
export const editPath = `edit`;

export const loginPathAbs = '/login';

// CLAIM
export const claimsPathAbs = '/claims';
export const claimsPath = 'claims';
export const tasksPath = `tasks`;
export const documentsPath = `documents`;
export const contactsPath = `contacts`;
export const correspondencePath = `correspondence`;
export const chatPath = `chat`;
export const createMessagePath = `create-message`;
export const linkedClaimsPath = `linked-claims`;
export const newClaimPath = `${claimsPathAbs}/new`;

// TASK
export const tasksPathAbs = '/tasks';
export const formsPath = `forms`;
export const newTaskPath = `${tasksPathAbs}/new`;

// PEOPLE
export const peoplePathAbs = '/people';
export const newPeoplePath = `${peoplePathAbs}/new`;

// ORGANISATIONS
export const organisationsPathAbs = '/organisations';
export const newOrganisationsPath = `${organisationsPathAbs}/new`;

// CUSTOMERS
export const customersPathAbs = '/customers';

// ACCOUNT
export const accountPathAbs = '/account';
