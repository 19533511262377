import React from 'react';

const PresentationButton = ({
  backgroundColor,
  buttonType,
  buttonSize,
  className,
  buttonIcon,
  buttonIconSingle,
  icon,
  value,
  disabled,
  style = {},
}) => {
  return (
    <div
      style={{ backgroundColor, ...style }}
      className={`btn ${buttonType} ${buttonSize} ${className} ${buttonIcon} ${buttonIconSingle}`}
      disabled={disabled}
    >
      <div className="btn-content clickable">
        {icon}
        <span className="btn-text">{value}</span>
      </div>
    </div>
  );
};

export default PresentationButton;
