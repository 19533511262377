import config from 'utils/config';

export const { apiPath, apiCMSPath, apiPathSecond } = config;

// APP paths
export const rootAppPath = '/';
export const tasksAppPath = 'tasks';
export const claimsAppPath = 'claims';
// export const tasksAppPath = '/tasks';
// export const claimsAppPath = '/claims';
export const claimsNewPath = `${claimsAppPath}/new`;
export const customersAppPath = '/customers';
export const peoplesAppPath = '/people';
export const organizationsAppPath = '/organisations';
export const errorPath = '/not-found';
export const loginAppPath = '/login';
export const accountAppPath = '/account';
export const ReusableComponentsPath = '/reusable-components';
export const setPasswordPath = '/set-password';
export const refreshEmailPath = '/me/confirm';
export const fingerprintsAppPath = '/fingerprints';
export const trustySuccessPath = '/trustly_account_selection_success_custom';
// S&G Repair Status Page
export const repairStatus = '/repair-status';
export const repairStatusLogin = '/repair-status/login';

// API paths
export const rolesPath = `${apiPathSecond}/auth/roles`;

// SESSION
export const sessionsPath = `${apiPath}/sessions`;
export const sessionsPathSecond = `${apiPathSecond}/sessions`;

// SETTINGS
export const settingsPath = `${apiPath}/settings`;

// STATIC TEXTS
export const staticTextsPath = `${apiPath}/static_texts`;

// CUSTOMERS
export const customersPath = `${apiCMSPath}/customers`;
export const customerPath = (id) => `${customerPath}/${id}`;

// CLAIMS
export const claimsPath = `${apiPath}/claims`;
export const claimsCmsPath = `${apiCMSPath}/claims`;
export const claimsPathSecond = `${apiPathSecond}/claims`;
export const claimLinksPath = `${claimsPathSecond}/links`;
export const getClaimPath = (id) => `${apiPathSecond}/claims/${id}`;
export const restoreClaimPath = (id) => `${getClaimPath(id)}/restore`;
export const duplicateClaimPath = (id) => `${getClaimPath(id)}/clone`;
export const getDeleteClaimAnswerPath = (claimId, answerId) =>
  `${apiCMSPath}/claims/${claimId}/answers/${answerId}`;
export const getCorrespondenceDocumentsPath = (id) =>
  `${apiPathSecond}/claims/${id}/delivered_notifications`;
export const getCorrespondenceEventsPath = (id) =>
  `${apiPathSecond}/delivered_notification_events?delivered_notification_id=${id}`;
export const getClaimDocumentsPath = (id) =>
  `${apiPathSecond}/claims/${id}/documents`;
export const getClaimTasksPath = (id) => `${apiPathSecond}/claims/${id}/forms`;
export const claimTasksPath = (id) => `${apiPathSecond}/claims/${id}/tasks`;
export const getUpdateClaimPath = (id) =>
  `${apiCMSPath}/claims/${id}/answers/update_all`;
export const deleteClaimsPath = `${apiPathSecond}/claims/delete_batch`;

export const getLinkedClaimsPath = (id) =>
  `${claimsPathSecond}/${id}/linked_claims`;
export const getUpdateLinkedClaimsPath = (id) =>
  `${claimsPathSecond}/links/${id}`;
export const getLinkedClaimsSearchPath = (id) =>
  `${claimsPathSecond}/${id}/linked_claims/search`;
export const generateAttestivReportPath = (id) =>
  `${getClaimPath(id)}/generate_attestiv_report`;

export const reassignAgentPath = `${claimsPathSecond}/reassign_batch`;

// FROM_TYPES
export const formTypesPath = `${apiPath}/form_types`;

// FORMS
export const tasksPath = `${apiPathSecond}/forms`;
export const tasksPathFirst = `${apiCMSPath}/forms`;
export const taskPath = (id) => `${tasksPath}/${id}`;
export const getCompleteFormPath = (id) => `${apiCMSPath}/forms/${id}`;
export const getDeleteFormAnswerPath = (formId, answerId) =>
  `${apiCMSPath}/forms/${formId}/answers/${answerId}`;
export const duplicateTaskPath = (id) => `${taskPath(id)}/clone`;
export const tasksByAgentPath = (agentsId = []) =>
  `${tasksPath}?agent_id_in=[${agentsId.join(',')}]`;

// USERS
export const usersPath = `${apiCMSPath}/users`;
export const usersPathSecond = `${apiPathSecond}/users`;
export const getUserPath = (id) => `${apiPathSecond}/users/${id}`;

export const getCustomerClaimPath = (id, claimId) =>
  `${apiCMSPath}/users/${id}/claims/${claimId}`;

export const getCustomerDocumentsPath = (id) =>
  `${apiCMSPath}/users/${id}/documents`;
export const getCustomerClaimsPath = (id) => `${apiCMSPath}/users/${id}/claims`;

// PEOPLE
export const peoplesPath = `${apiPathSecond}/persons`;
export const getPeoplePath = (id) => `${peoplesPath}/${id}`;
export const getPeopleClaims = (id) => `${peoplesPath}/${id}/claims`;

// ORGANIZATION
export const organizationsPath = `${apiPathSecond}/organizations`;
export const getOrganizationPath = (id) => `${organizationsPath}/${id}`;
export const getOrganizationClaims = (id) =>
  `${organizationsPath}/${id}/claims`;

// DOCUMENTS
export const documentsPath = `${apiCMSPath}/documents`;
export const documentsPathV2 = `${apiPathSecond}/documents`;
export const documentsPathSecond = `${apiPathSecond}/documents`;
export const getDocumentsPath = (id) => `${apiCMSPath}/documents/${id}`;
export const getDocumentsPathSecond = (id) => `${documentsPathSecond}/${id}`;
export const documentsPathv2 = `${apiPathSecond}/documents`;
export const documentsMetadataPath = `${apiPathSecond}/metadata_documents`;

// CLAIM_TYPES
export const claimTypesPath = `${apiPath}/claim_types`;

// PASSWORD_RECOVERIES
export const recoveryPath = `${apiPath}/password_recoveries`;

// CONTACTS
export const getContactsPath = (id) => `${apiPathSecond}/claims/${id}/contacts`;
export const searchContactablesPath = `${apiPathSecond}/contacts/search_contactables`;
export const contactsPath = `${apiPathSecond}/contacts`;
export const getContactPath = (id) => `${apiPathSecond}/contacts/${id}`;

//THREADS
export const messagePath = `${apiPathSecond}/threads/messages`;

//REFRESH EMAIL
export const refreshEmailApiPAth = `${apiPathSecond}/users/confirm`;

// UNLOCK ACCOUNT
export const unlockAccountPath = `${apiPathSecond}/webhooks/users/unlock`;

// ANSWERS and TASK ANSWERS
export const answersPath = `${apiPathSecond}/answers`;
export const getAnswerPath = (id) => `${apiPathSecond}/answers/${id}`;
export const updateTaskAnswersPath = (taskId) =>
  `${apiPathSecond}/forms/${taskId}/answers/update_all`;

// FILTERED SEARCH
export const filteredSearchPath = ({ questionId, value }) =>
  `${apiPathSecond}/questions/${questionId}/filtered_search?query=${value}`;
