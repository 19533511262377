import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './styles.scss';
import PresentationButton from './PresentationButton';

const Button = ({
  className = '',
  type = '',
  backgroundColor = '',
  onClick = () => {},
  size = '',
  value,
  name,
  disabled = false,
  icon,
  pathTo,
  contentReverse = false,
  presentation = false,
  targetBlank = false,
  isIconSuffix,
  style = {},
}) => {
  const buttonType = type ? `btn-${type}` : '';
  const buttonSize = size ? `btn-${size}` : '';
  const buttonIcon =
    icon && value ? `btn-icon ${!isIconSuffix ? 'right' : 'left'}` : '';
  const buttonIconSingle = icon && !value ? 'btn-icon-single' : '';
  const reverseClass = contentReverse ? 'btn-content reverse' : 'btn-content';
  return !presentation ? (
    <Fragment>
      {!pathTo ? (
        <button
          style={{ backgroundColor, ...style }}
          className={`btn ${buttonType} ${buttonSize} ${className} ${buttonIcon} ${buttonIconSingle}`}
          onClick={onClick}
          name={name}
          disabled={disabled}
          value={value}
        >
          <div className="btn-content">
            {!isIconSuffix ? icon : null}
            <span className="btn-text">{value}</span>
            {isIconSuffix ? icon : null}
          </div>
        </button>
      ) : (
        <Fragment>
          {targetBlank ? (
            <a
              href={pathTo}
              target="_blank"
              rel="noopener noreferrer"
              style={{ backgroundColor, ...style }}
              className={`btn btn-link ${buttonType} ${buttonSize} ${className} ${buttonIcon} ${buttonIconSingle}`}
            >
              <div className={reverseClass}>
                {icon}
                <span className="btn-text">{value}</span>
              </div>
            </a>
          ) : (
            <Link
              to={pathTo}
              onClick={onClick}
              className={`btn btn-link ${buttonType} ${buttonSize} ${className} ${buttonIcon} ${buttonIconSingle}`}
              style={{ backgroundColor, ...style }}
            >
              <div
                style={{ backgroundColor, ...style }}
                className={reverseClass}
              >
                {icon}
                <span className="btn-text">{value}</span>
              </div>
            </Link>
          )}
        </Fragment>
      )}
    </Fragment>
  ) : (
    <PresentationButton
      backgroundColor={backgroundColor}
      buttonType={buttonType}
      buttonSize={buttonSize}
      className={className}
      buttonIcon={buttonIcon}
      buttonIconSingle={buttonIconSingle}
      icon={icon}
      value={value}
      disabled={disabled}
      style={style}
    />
  );
};

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  pathTo: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  contentReverse: PropTypes.bool,
  presentation: PropTypes.bool,
  icon: PropTypes.node,
  targetBlank: PropTypes.bool,
};

export default Button;
